


.mq-chats {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-bottom: 16px;

  .searchForm {
    padding: 16px;
  }
  .searchUserBox {
    display: flex;
    margin-right: 24px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    border-radius: 8px;
    border: 1px solid #DDE8E8;
    padding-right: 20px;
  
  
    .searchTypeBox {
      position: relative;
      border-right: 1px solid #DDE8E8;
      padding: 10px 20px;
      width: 20%;
  
      .searchType {
        display: flex;
        align-items: center;
        font-size: 16px;
  
        ion-icon {
          margin-left: 8px;
        }
  
      }
  
      .selectSearchType {
        display: none;
        position: absolute;
        left: 0;
        bottom: -90px;
        z-index: 99;
  
        ul {
          margin: 0;
          padding: 0;
        }
  
        li {
          text-align: center;
          width: 100px;
          height: 30px;
          list-style: none;
          //background: #fff;
          background-color: rgb(236, 235, 235);
          font-size: 16px;
          line-height: 30px;
        }
  
        li:hover {
          background-color: #fff;
        }
      }
  
      &:hover {
        cursor: pointer;
  
        .selectSearchType {
          display: block;
        }
      }
    }
  
    .input {
      //border: 1px solid #DDE8E8;
      border-radius: 4px;
      padding-left: 16px;
      --padding-start: 16px
    }
  
  
    .searchButton {
      cursor: pointer;
      color: #333;
      font-size: 24px;
    }
  
    .searchButton:hover {
      color: #000;
      font-size: 26px;
  
    }
  
  }

  .pullChatsBtnOuter {
    padding: 0 16px;
  }
  .bottomBtn {
    height: 40px;
    font-size: 16px;
  }
}


