.opBtn {
  width: 90px;
  padding: 0 12px;
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;

  &.unfollow.hover {
    border-radius: 6px;
    border: 1px solid #FEC8C8;
    color: #EF4343;
    background: #FFF;
  }

  &.following {
    color: var(--color-gray-700, #3F3F46);
    border: 1px solid var(--color-gray-200, #E4E4E7);
    background: var(--color-base-white, #FFF);
  }
}

