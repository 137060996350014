.container {
  padding-top: 16px;;
}

.addressBox {
  display: flex;
  width: 260px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  margin: 0 auto;
  border: 1px solid #e4e4e7;
  color: #3f3f46;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  .centerText {
    margin-left: 4px;
    margin-right: 6px;
  }
  .addressText {
    line-height: 20px;
  }

  div:first-child {
    height: 30px;
  }
}

.textBox {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  text-align: center;
  .title {
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    color: #18181b;
    margin-top: 16px;
  }
  .subTitle {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71717a;
  }
}

.inputContainer {
  padding: 16px 0;

  .inputBox {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    text-align: left;
    margin-top: 16px;
    color: #18181b;
    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .inputValue {
      margin-top: 8px;
      height: 40px;
      border: 1px solid #e4e4e7;
      border-radius: 8px;
      display: flex;
      padding: 0 16px;
      align-items: center;

      input {
        border: none;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #18181b;
        outline: none;
      }

      .eyesBox {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
  }

  .errorBox {
    padding: 12px;
    margin-top: 8px;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    min-height: 40px;
    border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    background-color: #fef1f1;
    color: #ef4343;
    font-weight: 500;
    font-size: 12px;
  }

  .errorIcon {
    width: 13px;
    height: 13px;
    margin-right: 6px;
  }

  .tipsText {
    padding: 12px;
    margin-top: 16px;
    border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    line-height: 16px;
    background: #fdfdfd;
    border: 1px solid #e4e4e7;
    font-weight: 500;
    font-size: 12px;
    color: #52525b;
  }
}

.btnBox {
  margin-top: 16px;
  height: 40px;

  .button {
    width: 100%;
    height: 40px;
  }
}