.mq-chat-list {
  padding-top: 12px;
  flex: 1;
  overflow-y: auto;
}

.mq-channel-item {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  .avatar {
    width: 40px;
    height: 40px;
  }
  .info {
    flex: 1;
    overflow: hidden;
  }
  .nickname {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #18181B;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .address {
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}