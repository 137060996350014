.container {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background: #F6F6F6;
}

.mainContainer {
  width: 100%;
  padding-top: 24px;
  .content {
    min-width: 1220px;
    gap: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

ion-card {
  padding: 15px;
}

ion-button {
  width: 100%;
}