// @import "../styles/index.scss";

.login_container {
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /*background: url("../../assets/banner_web 1.png")  no-repeat top center;*/
}

.test-bgc {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connectBtnBox {
  width: 482px;
  background: #FFFFFF;
  border: 1px solid #E4E4E7;
  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 6px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  text-align: center;
  padding: 56px 48px;

  .pwdTip {
    margin-top: 4px;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #71717A;
    text-align: left;
  }

  .notInstalled {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #71717A;

    .warningIcon {
      display: inline-block;
      vertical-align: -4px;
    }
    .ephasis {
      cursor: pointer;
      color: #663CEE;
    }

    svg {
      margin-top: 3px;
      margin-right: 7px;
    }
  }
}

.connectMobileBtnBox {
  border: none;
  box-shadow: none;
}

.connectBtnBox .connectBtnBoxText {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #71717A;
  margin-top: 12px;
}

.connectBtnBox .connectBtnBoxTitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  color: #18181B;
  margin-top: 24px;
}

.connectBtnBox .walletConnect-btnBox {
  width: 100%;
  margin-top: 34px;
}

.connectBtnBox .walletConnect-btnBox .channelBtn {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
}

.inputContainer {
  padding: 16px 0;

  .inputBox {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    text-align: left;
    margin-top: 16px;

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    .inputValue {
      margin-top: 8px;
      height: 40px;
      border: 1px solid #e4e4e7;
      border-radius: 8px;
      display: flex;
      padding: 0 16px;
      align-items: center;

      input {
        border: none;
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        outline: none;
      }

      .eyesBox {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }

    //   @include themeify {
    //     color: #18181b;
    //   }
  }

  .errorBox {
    padding: 12px;
    margin-top: 8px;
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    min-height: 40px;
    border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    background: #fef1f1;
    color: #ef4343;
    font-size: 12px;
    //   @include themeify {
    //     background-color: #fef1f1;
    //     color: #ef4343;
    //     font-weight: 500;
    //     font-size: 12px;
    //   }

    .errorIcon {
      width: 13px;
      height: 13px;
      margin-right: 6px;
    }
  }
}


.step_text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
}

.step_box .up_text {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  height: 36px;
}

.step_box .down_text {
  margin-top: 12px;
  padding: 0 54px;
  line-height: 150%;
  color: #424242;
}

.step_box .step_text {
  margin-top: 78px;
  line-height: 16px;
}

.button_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  row-gap: 10px;
}

.sign_btn {
  width: 195px;
  height: 48px;
  border-radius: 49px;
  border: none;
  color: #000;
  background: #fff;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(230, 230, 230);
}

.sign_btn:hover {
  /*background: rgba(51, 125, 207, 0.6);*/
  /* opacity: 0.6; */
  color: #fff;
  background-color: #337dcf;
  border-color: transparent;
  cursor: pointer;
}

.modalLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.modalContent {
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}

.modalContentBody {
  flex: 1;
}

.ModalBtnGroupBtn {
  cursor: pointer;
  width: 195px;
  height: 48px;
  border-radius: 49px;
  border: none;
  color: #ffffff;
  background: #337dcf;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.modalContentBodyInput {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modalContentBodyInput input,
select {
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;
  outline: none;
  height: 40px;
  width: 60%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding-left: 12px;
}

.codeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.code {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.codeItem {
  font-size: 60px;
  font-weight: bold;
  color: blue;
}

.tips {
  padding: 0 40px;
  font-size: 24px;
  font-weight: bold;
  color: red;
  text-align: center;
}

.step {
  margin-top: 80px;
  padding: 0 50px;
}

.btnIcon {
  width: 20px;
  margin-right: 2px;
}