ion-card {
  padding: 15px;
}

.mobilePannel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 670px;
  background: #ffffff;
  /* shadow/Small */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  min-width: 748px;
  width: 900ox;
  ion-button {
    width: 100%;
  }

  .header {
    position: relative;
    display: flex;
    padding: 0 19px;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #dde8e8;

    .refresh {
      position: absolute;
      right: 16px;
      display: flex;
      align-items: center;
      color: #663CEE;
      cursor: pointer;
      .refreshTitle {
        margin-right: 8px;
      }
      svg {
        margin-top: 6px;
        color: #663CEE;
      }
    }
  }

  .chatTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .title {
      margin-left: 10px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #18181b;
      height: 22px;
      width: 160px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.notLoginMask {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 48px;

  border-radius: 12px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .largeTip {
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .tip {
    margin-top: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #71717a;
  }

  .button {
    width: 50%;
  }
}

ion-header {
  border: 1px solid #f2f2f2;
  border-bottom: none;

  ion-toolbar {
    --border-width: 0 !important;
  }
}

.demoContent {
  border-top: none;
  --padding-start: 20px;
  --padding-end: 20px;
  height: 500px;

  .messagesContent {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 90%;

    .messageBox {
      width: 100%;
      border-bottom: 1px solid #f2f2f2;
      padding: 10px 15px 15px;
      position: relative;

      .userTitleBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;

        .userInfoBox {
          display: flex;

          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }

          .username {
            margin-left: 10px;
            font-family: "Helvetica Neue", sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 23px;
            color: #000000;
          }

          .createAt {
            margin-left: 8px;
            font-family: "Helvetica Neue", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 19px;
            color: #4c5667;
          }
        }
      }

      .messageBodyContentBox {
        display: flex;

        .messageContentsText {
          width: 100%;
          margin-top: 5px;
          padding-left: 34px;
          //padding-right: 33px;
          font-family: "Helvetica Neue", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #424242;

          a {
            text-decoration: none;
            color: #5a67e2;
          }
        }
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  background: #fff;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 72px;
  gap: 8px;
  border-radius: 0 0 12px 12px;

  .messageInput {
    border: 1px solid #dde8e8;
    border-radius: 50px;
    padding-left: 16px;
    --padding-start: 16px;
    margin-right: 8px;
  }

  ion-button {
    width: 50px;
  }
}
