.chatsPanel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 448px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  max-height: 670px;
  overflow: hidden;
}