.snapHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  box-sizing: border-box;
  height: 72px;
  background: #FFFFFF;
  border-bottom: 1px solid #F4F4F5;

  .logo {
    width: 201px;
    height: 24px;
  }

  .loggoutBtn {
    padding: 0 12px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    svg {
      margin-right: 4px;
    }
  }
}