
.addressBox {
  display: flex;
  width: 260px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  margin: 0 auto;
  border: 1px solid #e4e4e7;
  color: #3f3f46;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  .centerText {
    margin-left: 4px;
    margin-right: 6px;
  }
  .addressText {
    line-height: 20px;
  }

  div:first-child {
    height: 30px;
  }
}